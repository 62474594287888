<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-6 col-xxl-6 p-0 card_item">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <div class="row">
                  <div class="col-md-10 offset-md-1 pt-2">
                    <div class="row justify-content-center">
                      <div class="col-auto text-center p-0 medium green-text-dark font12 letter_spacing">
                        DAILY SADAQAH: {{item.debitOrderID}}
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-end cursor d-none d-md-block" @click="$emit('close')">
                    <ios-close-icon class="inline-icon green-text-dark" />
                  </div>
                </div>
                <div class="row m-0 justify-content-center" >
                  <div class="col-12 mt-3" v-if="!isEdit">
                    <div class="row mx-0 justify-content-center" >
                      <div class="col-12 text-center mb-3 p-0 medium font10 letter_spacing card_title">
                        <span v-if="item.aifReference">{{item.aifReference}}</span>
                        <span v-else>{{item.donorName}}</span>
                        <div class="row m-0 justify-content-center" >
                          <div class="col-4 text-center pt-3 border_bottom">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0 text-center">
                      </div>
                    </div>
                  </div>
                  <RecurringDonationEditPopup v-if="isEdit" :item="item" :itemDetails="details" />
                </div>
                <!--TOTAL/FREQUENCY/DATE-->
                <div class="row m-0 justify-content-center" v-if="!isEdit">
                  <!--DESKTOP-->
                  <div class="col-11 px-0 my-3 d-none d-md-block">
                    <div class="row align-items-center justify-content-center">
                      <div class="col-md-5 text-center">
                        <Button color="green" width="100" :btnText="displayTotal" />
                      </div>
                      <div class="col-md-5 text-center">
                        <Button color="green" width="100" :btnText="date" />
                      </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                      <div class="col-md-5 text-center green-text py-2">
                        <div class="medium green-text font10 letter_spacing">
                          DAILY AMOUNT
                        </div>
                      </div>
                      <div class="col-md-5 text-center green-text py-2">
                        <div class="medium green-text font10 letter_spacing">
                          DATE CREATED
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--END DESKTOP-->
                  <!--MOBILE-->
                  <div class="col-12 px-0 my-3 d-md-none">
                    <div class="row align-items-center">
                      <div class="col-12 text-center green-text">
                        <Button color="green" width="100" :btnText="displayTotal" />
                      </div>
                      <div class="col-12 text-center green-text py-2">
                        <div class="medium green-text font10 letter_spacing">
                          DAILY AMOUNT
                        </div>
                      </div>
                      <div class="col-12 text-center green-text mt-3">
                        <Button color="green" width="100" :btnText="date" />
                      </div>
                      <div class="col-12 text-center green-text py-2">
                        <div class="medium green-text font10 letter_spacing">
                          DATE CREATED
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--END MOBILE-->
                </div>
                <!--END TOTAL/FREQUENCY/DATE-->
              </div>
            </div>
          </div>
          <!--BUTTONS-->
          <div class="col-12 px-4 align-self-end background-green">
            <div class="row py-3 align-items-center justify-content-center" v-if="isEdit">
              <div class="col-8 col-md-5 px-2 mb-2 mb-md-0" >
                <Button color="red" size="" width="100" btnText="Cancel Changes" icon="arrow" @buttonClicked="isEdit = false">
                  <ios-close-circle-outline-icon class="inline-icon" />
                </Button>
              </div>
              <div class="col-8 col-md-5 px-2" >
                <Button color="green_light" size="" width="100" btnText="Save Changes" icon="arrow" @buttonClicked="isEdit = false">
                  <IconEdit size="size16"/>
                </Button>
              </div>
            </div>
            <div class="row py-3 align-items-center justify-content-center" v-else>
              <div class="col-md-5 px-1 mb-2 mb-md-0 order-last order-md-first" >
                <Button color="red" size="" width="100" btnText="Cancel" icon="arrow" @buttonClicked="$emit('close')">
                  <ios-close-circle-outline-icon class="inline-icon" />
                </Button>
              </div>
              <!-- <div class="col-md-4 px-1 mb-2 mb-md-0" >
                <Button color="green_light" size="" width="100" btnText="Edit Donation" icon="arrow" @buttonClicked="isEdit = true">
                  <IconEdit size="size16"/>
                </Button>
              </div> -->
              <!-- <div class="col-md-5 px-1 mb-2 mb-md-0" >
                <Button color="green_light" size="" width="100" btnText="Edit Card/Bank" icon="arrow" @buttonClicked="$emit('editCard')">
                  <IconCard size="size16"/>
                </Button>
              </div> -->
            </div>
          </div>
          <!--END BUTTONS-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    RecurringDonationEditPopup: defineAsyncComponent(() => import('@/views/RecurringDonationEditPopup.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    // IconCard: defineAsyncComponent(() => import('@/components/icons/IconCard.vue')),
    'ios-close-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close-circle-outline.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Recurring Donation View Popup',
  props: ['item'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isEdit: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    displayTotal () {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(this.item.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.startDate))
      return date.toLocaleString(DateTime.DATE_SMALL)
    }
    // frequency () {
    //   const lowercase = this.item.frequency.toLowerCase()
    //   return lowercase[0].toUpperCase() + lowercase.slice(1)
    // },
    // method () {
    //   const lowercase = this.item.donorBankAccount.bankName.toLowerCase()
    //   return lowercase[0].toUpperCase() + lowercase.slice(1)
    // }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../../../../../../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
</style>
